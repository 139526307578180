<template>
  <div id="mapContainer"></div>
</template>

<script>
import "leaflet/dist/leaflet.css";
import L from "leaflet";
L.RasterCoords = require('leaflet-rastercoords');

export default {
  name: "LeafletMap",
  data() {
    return {
      map: null,
      redMarker: L.icon({
      iconUrl: require('@/assets/marker-icon-red.png'),
      iconRetinaUrl: require('@/assets/marker-icon-red.png'),
      iconSize: [25, 41],
      iconAnchor: [12, 41],
      popupAnchor: [-0, -31],
      shadowUrl: require('@/assets/marker-shadow.png'),
      shadowSize: [41, 41],
      shadowAnchor: [14, 41]
    })
    };
  },
  mounted() {
    var minZoom = 1;
    var maxZoom = 5;
    var imgSize = [
      4097,
      2142
    ]

    this.map = L.map("mapContainer", {
      crs: L.CRS.Simple,
      minZoom: minZoom,
      maxZoom: maxZoom,
      zoomSnap: 0,
      zoomDeleta: 0.25
    });

    var rasterCoords = new L.RasterCoords(this.map, imgSize);
    var redMarker = this.redMarker;
    this.map.setView(rasterCoords.unproject([imgSize[0], imgSize[1]]), 3)

    this.map.options.crs = L.CRS.Simple;
    L.tileLayer("https://tiles.tarkoved.com/customs/{z}/{x}/{y}.png", {
        bounds: rasterCoords.getMaxBounds(),
        maxNativeZoom: rasterCoords.zoomLevel()
    }).addTo(this.map);

    // set markers on click events in the map
    this.map.on('click', function (event) {
      // any position in leaflet needs to be projected to obtain the image coordinates
      console.log(event);
      var coords = rasterCoords.project(event.latlng)
      var marker = L.marker(rasterCoords.unproject(coords), {
        icon: redMarker
      })
        .addTo(this)
      marker.bindPopup('[' + Math.floor(coords.x) + ',' + Math.floor(coords.y) + ']')
        .openPopup()
    })

      var marker = L.marker(rasterCoords.unproject([100, 100]), {
        icon: this.redMarker
      }).on('mouseover', function() {
        marker.bindPopup('essa').openPopup();
      }).on('mouseout', function() {
        marker.closePopup();
      })
        .addTo(this.map);
  },
  onBeforeUnmount() {
    if (this.map) {
      this.map.remove();
    }
  },
  created() {

  }
};
</script>

<style scoped>
#mapContainer {
  width: 100%;
  height: 100vh;
}
</style>